import React, { useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { PageProps } from 'gatsby'
import Container from '@material-ui/core/Container'
import DialogTitle from '@material-ui/core/DialogTitle'
import { LayoutContext } from 'src/features/layout'
import SettingsContent from 'src/features/settings/settings-content'
import SEO from 'src/common/seo'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import styled from 'styled-components'

//TODO: refactor out MUI container

const Settings = ({ location }: PageProps) => {
  const props = useMotionNavOffsetStyle({ cssProp: 'padding' })
  const { setLocation } = useContext(LayoutContext)

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <SEO title="Settings" />
      <motion.div {...props}>
        <SettingsContainer maxWidth="sm">
          <DialogTitle>Settings</DialogTitle>
          <SettingsContent />
        </SettingsContainer>
      </motion.div>
    </>
  )
}

export default Settings

const SettingsContainer = styled(Container)`
  padding-bottom: ${(p) => p.theme.spacing(2)}px;
`
